import { apiUrl } from "config";
import * as createApi from "lib/createRestApiClient";

const name = "api/media";

export const getAll = createApi.getAll<Media>(apiUrl, name);
export const getOne = createApi.getOne<Media>(apiUrl, name);
export const create = createApi.create<Media>(apiUrl, name);
export const update = createApi.update<Media>(apiUrl, name);
export const del = createApi.del<Media>(apiUrl, name);
