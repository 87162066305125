import { apiUrl } from "config";
import { GetAll } from "lib/createRestApiClient";
import { handleFetch } from "lib/handleResponse";

const name = "api/events";

export const getAll: GetAll<EventStat> = async ({ token, query }) => {
  return handleFetch(async () => {
    const headers: Record<string, string> = {};
    if (token) headers["Authorization"] = `Bearer ${token}`;

    const search =
      typeof query === "string"
        ? query
        : typeof query === "object"
        ? new URLSearchParams(query).toString()
        : "";

    return await fetch(
      search ? `${apiUrl}/${name}?${search}` : `${apiUrl}/${name}`,
      {
        method: "GET",
        headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
      }
    );
  });
};
