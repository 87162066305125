import { useRecoilState, useRecoilValue } from "recoil";
import { useToken } from "states/auth/hooks";
import { useLoadItems } from "states/article/hooks";
import { currentIdState, itemState } from "states/article";
import { create, update } from "api/article";
import DetailsForm from "./DetailsForm";

const stateName = "article";

const Details = () => {
  const [currentId, setCurrentId] = useRecoilState(currentIdState(stateName));
  const currentItem = useRecoilValue(
    itemState({ name: stateName, id: currentId })
  );
  const loadItems = useLoadItems(stateName);
  const token = useToken();

  const save = async (item: Article) => {
    if (currentId) {
      await update({
        id: currentId,
        entity: item,
        token,
      });
    } else {
      const { data } = await create({
        entity: item,
        token,
      });

      if (data) setCurrentId(data._id);
    }

    await loadItems();
  };

  return <DetailsForm id={currentId} item={currentItem} onSave={save} />;
};

export default Details;
