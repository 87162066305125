import { formData } from "./formData";

export const getMediaType = (file?: File) =>
  file?.type.includes("image")
    ? "image"
    : file?.type.includes("video")
    ? "video"
    : file?.type.includes("audio")
    ? "audio"
    : "unknown";

interface UploadParams {
  uploadUrl: string;
  token?: string;
  file?: File;
  // type: "image" | "video";
  dir: "thumbnail" | "media" | string;
  onLoad: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
  onProgress?: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
}

/** 上传一个文件到指定url */
const upload = ({
  uploadUrl,
  token,
  file,
  // type,
  dir,
  onLoad,
  onProgress,
}: UploadParams) => {
  if (!file) return;

  const fd = formData({
    file, //type,
    dir,
  });

  const xhr = new XMLHttpRequest();

  // xhr.upload.onprogress = onProgress;
  // xhr.upload.onload = onLoad;
  xhr.addEventListener("load", function (e) {
    onLoad(this, e);
  });

  if (onProgress) {
    xhr.addEventListener("progress", function (e) {
      onProgress(this, e);
    });
  }

  // XHR - Make Request
  xhr.open("POST", uploadUrl, true);
  // xhr.open("POST", apiUrl + "/api/upload", true);
  if (token) xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send(fd);
};

export default upload;
