import Container from "components/MainDetails/Container";
import List from "./List";
import Details from "./Details";

const Figure = () => {
  return (
    <Container>
      <List stateName="figure" />
      <Details />
    </Container>
  );
};

export default Figure;
