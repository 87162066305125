import styles from "./index.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        © 2022 - {new Date().getFullYear()} 女性创能驿站 - 管理后台
      </div>
    </footer>
  );
};

export default Footer;
