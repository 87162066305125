import { Outlet } from "react-router";
import RequireAuth from "lib/RequireAuth";
import { useIsAuthenticated } from "states/auth/hooks";
import Footer from "./Footer";
import Navbar from "./Navbar";
import styles from "./index.module.scss";

const DefaultLayout = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <RequireAuth isAuthenticated={isAuthenticated}>
      <div className={styles.page}>
        <Navbar />
        <div className={styles.main}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </RequireAuth>
  );
};

export default DefaultLayout;
