import { apiUrl } from "config";
import * as createApi from "lib/createRestApiClient";

const name = "api/companies";

export const getAll = createApi.getAll<Company>(apiUrl, name);
export const getOne = createApi.getOne<Company>(apiUrl, name);
export const create = createApi.create<Company>(apiUrl, name);
export const update = createApi.update<Company>(apiUrl, name);
export const del = createApi.del<Company>(apiUrl, name);
