import { useEffect, useState } from "react";
import { getAll } from "api/event";
import { useToken } from "states/auth/hooks";
import styles from "./index.module.scss";

const UserEvent = () => {
  const [list, setList] = useState<Array<EventStat>>([]);
  const token = useToken();

  useEffect(() => {
    const run = async () => {
      const { data, error } = await getAll({ token });

      if (!error && data) setList(data);
    };

    run();
  }, [token]);

  return (
    <div className={styles["event-stat"]}>
      {list.length ? (
        <table>
          <caption className={styles.title}>访问统计 </caption>
          <thead>
            <tr>
              <th>日期</th>
              <th>创业园/网站</th>
              <th>职位/联系方式</th>
            </tr>
          </thead>
          <tbody>
            {list.map((stat) => (
              <tr key={stat._id}>
                <td>{stat._id}</td>
                <td>{stat.figureClicks}</td>
                <td>{stat.jobClicks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.empty}>暂无数据</div>
      )}
    </div>
  );
};

export default UserEvent;
