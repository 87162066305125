import { useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import upload from "lib/upload";
import PlusIcon from "lib/PlusIcon";
import { useToken } from "states/auth/hooks";
import { apiUrl } from "config";
import styles from "./Uploader.module.scss";

interface Props {
  title?: string;
  type: "image" | "video" | Array<"image" | "video">;
  dir: "thumbnail" | "media";
  onLoad: (file: File, fileUrl: string) => Promise<void> | void;
}

const Uploader = ({ title, type, dir, onLoad }: Props) => {
  const [value, setValue] = useState<File>();
  const token = useToken();

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    const handleLoad = async (
      xhr: XMLHttpRequest,
      e: ProgressEvent<EventTarget>
    ) => {
      setValue(file);

      if (xhr.responseText) {
        const resJson = JSON.parse(xhr.responseText);
        await onLoad(file, resJson.file);
      } else {
        console.log("xhr response is empty");
        throw new Error(`Upload failed. The response is ${xhr.responseText}`);
      }
    };

    upload({
      uploadUrl: apiUrl + "/api/upload",
      token,
      file,
      dir,
      onLoad: handleLoad,
    });
  };

  const acceptByType: Record<string, Accept> = {
    image: { "image/jpeg": [], "image/png": [] },
    video: { "video/*": [] },
  };

  const getAcceptType = () => {
    if (typeof type === "string") return acceptByType[type];

    return type.reduce(
      (previous, current) => ({ ...previous, ...acceptByType[current] }),
      {}
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: getAcceptType(),
    multiple: false,
  });

  return (
    <section>
      <div className={styles.title}>
        {title || "将图片文件拖动到此处，或点击选择文件"}
      </div>
      <div className={styles.main}>
        <div
          {...getRootProps({
            className: styles.dropzone,
          })}
        >
          <PlusIcon className={styles["plus-icon"]} size="200px" />
          <input {...getInputProps()} />
        </div>
        <div className={styles.info}>文件：{value?.name}</div>
      </div>
    </section>
  );
};

export default Uploader;
