import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface Props {
  loginUrl?: string;
  isAuthenticated: boolean;
  children: ReactNode;
}

const RequireAuth = ({
  loginUrl = "/login",
  isAuthenticated,
  children,
}: Props) => {
  const location = useLocation();

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={loginUrl} state={{ from: location }} />
  );
};

export default RequireAuth;
