import React from "react";
import { clamp } from "lib/clamp";

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  value?: string;
}

const TextBox = ({ value, style, ...props }: Props) => {
  const rows = value ? value.split("\n").length : 0;

  return (
    <textarea
      {...props}
      value={value}
      rows={clamp(rows + 2, 5, 20)}
      style={{ ...style, minWidth: "35em" }}
    />
  );
};

export default TextBox;
