import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useToken } from "states/auth/hooks";
import { currentArticleIdState } from "states/enrollment";
import { getAll } from "api/article";
import Main from "./Main";

const List = () => {
  const [currentId, setCurrentId] = useRecoilState(currentArticleIdState);
  const [list, setList] = useState<Array<Article>>([]);
  const token = useToken();

  useEffect(() => {
    const run = async () => {
      const { data, error } = await getAll({
        token,
        query: "category=enrollment",
      });

      if (!error && data) setList(data);
    };

    run();
  }, [token]);

  return (
    <Main
      title="会议报名"
      itemIds={list.map(({ _id }) => _id)}
      renderItem={(id) => (
        <span>{list.find(({ _id }) => id === _id)?.name || "未命名"}</span>
      )}
      onSelect={setCurrentId}
      currentId={currentId}
    />
  );
};

export default List;
