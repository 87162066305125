import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DefaultLayout from "components/Layout/DefaultLayout";
import Login from "components/Login";
import Company from "components/Company";
import Job from "components/Job";
import Figure from "components/Figure";
import Article from "components/Article";
import Enrollment from "components/Enrollment";
import UserEvent from "components/UserEvent";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Navigate to="companies" />} />
          <Route path="companies" element={<Company />} />
          <Route path="jobs" element={<Job />} />
          <Route path="figures" element={<Figure />} />
          <Route path="articles" element={<Article />} />
          <Route path="enrollments" element={<Enrollment />} />
          <Route path="events" element={<UserEvent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
