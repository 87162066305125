import { apiUrl } from "config";
import * as createApi from "lib/createRestApiClient";

const name = "api/admin/articles";

export const getAll = createApi.getAll<ArticleView>(apiUrl, name);
export const getOne = createApi.getOne<ArticleView>(apiUrl, name);
export const create = createApi.create<Article>(apiUrl, name);
export const update = createApi.update<Article>(apiUrl, name);
export const del = createApi.del<Article>(apiUrl, name);
