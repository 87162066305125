import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import DatePicker from "react-datepicker";
import QuillEditor from "lib/QuillEditor";
import MediaFinderContainer from "lib/MediaFinderContainer";
import { apiUrl } from "config";
import TextBox from "components/TextBox";
import Uploader from "components/Uploader";
import FormRow from "components/MainDetails/FormRow";
import { formatTags, parseTags } from "components/common";
import styles from "./index.module.scss";

type FormValues = Omit<Figure, "tags"> & { tags?: string };

interface Props {
  id: string;
  item?: FigureView;
  onSave: (item: Figure) => Promise<any>;
}

const DetailsForm = ({ id, item, onSave }: Props) => {
  const [image, setImage] = useState<string>();

  const submit = async (values: FormValues) => {
    const tags = parseTags(values.tags);

    await onSave({
      ...values,
      image,
      tags,
    });
  };

  useEffect(() => {
    setImage(item?.image || "");
  }, [item]);

  return (
    <div className={styles.details}>
      <div className={styles.title}>{id ? "编辑" : "创建"}</div>

      <Form<FormValues>
        key={id}
        initialValues={{
          pinned: false,
          isPublished: false,
          ...item,
          tags: formatTags(item?.tags),
        }}
        onSubmit={submit}
      >
        {({ handleSubmit, form, values, submitting }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            {id && <FormRow label="编号" control={id} />}

            <Field name="name" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="名称"
                  control={<input {...input} type="text" placeholder="名称" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <FormRow
              label="缩略图"
              control={
                image && (
                  <div className={styles.col}>
                    <img
                      className={styles.thumbnail}
                      src={`${apiUrl}/uploads/thumbnail/${image}`}
                      alt="thumbnail"
                    />
                    <div>{image}</div>
                  </div>
                )
              }
            />

            <FormRow
              label="上传图片"
              control={
                <Uploader
                  type="image"
                  dir="thumbnail"
                  onLoad={(_file, fileUrl) => setImage(fileUrl)}
                />
              }
            />

            <Field name="summary" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="简介"
                  control={<TextBox {...input} placeholder="人物简介" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="tags">
              {({ input, meta }) => (
                <FormRow
                  label="标签（每个一行）"
                  control={
                    <textarea {...input} placeholder="标签（每个一行）" />
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field name="introduction">
              {({ input, meta }) => (
                <FormRow
                  label="介绍"
                  control={
                    <QuillEditor
                      value={input.value}
                      onChange={input.onChange}
                      placeholder="请输入介绍内容"
                      mediaPicker={MediaFinderContainer}
                    />
                  }
                />
              )}
            </Field>

            <Field name="products">
              {({ input, meta }) => (
                <FormRow
                  label="产品介绍"
                  control={
                    <QuillEditor
                      value={input.value}
                      onChange={input.onChange}
                      placeholder="请输入产品介绍内容"
                      mediaPicker={MediaFinderContainer}
                    />
                  }
                />
              )}
            </Field>

            <Field name="website" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="官网"
                  control={<input {...input} type="text" placeholder="网站" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field
              name="pinned"
              parse={(v) => v === "yes"}
              format={(v) => (v ? "yes" : "no")}
            >
              {({ input, meta }) => (
                <FormRow
                  label="置顶"
                  control={
                    <select {...input}>
                      <option value="no">未置顶</option>
                      <option value="yes">已置顶</option>
                    </select>
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field name="isPublished" parse={(value) => value} type="checkbox">
              {({ input, meta }) => (
                <FormRow
                  label="状态"
                  control={
                    <label>
                      <input
                        {...input}
                        type="checkbox"
                        onChange={(e) => {
                          input.onChange(e);
                          // 当isPublished被勾中，并且publishedAt没有值的时候
                          // 设置一个默认的publishedAt值
                          if (!values.publishedAt && !values.isPublished)
                            form.change(
                              "publishedAt",
                              new Date().toISOString()
                            );
                        }}
                      />
                      发布
                    </label>
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="publishedAt">
              {({ input, meta }) => (
                <FormRow
                  label="发布日期"
                  control={
                    <div className={styles["compact-control"]}>
                      <DatePicker
                        selected={
                          input.value &&
                          ["string", "number", "date"].includes(
                            typeof input.value
                          )
                            ? new Date(input.value)
                            : null
                        }
                        onChange={(date) => {
                          input.onChange({
                            target: {
                              value: date ? date.toISOString() : date,
                            },
                          });
                        }}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm"
                      />
                    </div>
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <div className={styles.buttons}>
              <button color="secondary" onClick={() => form.reset()}>
                重置
              </button>
              <button color="primary" type="submit" disabled={submitting}>
                {submitting ? "提交中..." : "提交"}
              </button>
              {/* {hint && (
                  <span style={{ fontSize: "0.85em", color: "red" }}>{hint}</span>
                )} */}
            </div>
          </form>
        )}
      </Form>
      {/* <pre>{JSON.stringify(currentItem, null, 2)}</pre> */}
    </div>
  );
};

export default DetailsForm;
