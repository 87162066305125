import React, { useEffect, useState } from "react";
import { MediaFinder } from "@microhelper/tiny-components";
import upload, { getMediaType } from "lib/upload";
import { getAll, create, del } from "api/media";
import { useToken } from "states/auth/hooks";
import { apiUrl } from "config";

interface Props {
  onSelect: (items: Array<Media & { imageUrl: string }>) => void;
  onClose: () => void;
}

const MediaFinderContainer = ({ onSelect, onClose }: Props) => {
  const [items, setItems] = useState<Array<Media>>([]);

  const token = useToken();

  const getImageUrl = (fileName: string) =>
    `${apiUrl}/uploads/media/${fileName}`;

  const handleMediaUpload = (files: FileList | null) => {
    const file = files?.[0];

    if (!file) return;

    upload({
      uploadUrl: apiUrl + "/api/upload",
      token,
      file,
      // type: "image",
      dir: "media",
      // onProgress: progress
      //   ? (_xhr, e) => {
      //       progress((e.loaded / e.total) * 100);
      //     }
      //   : undefined,
      onLoad: async (xhr, _e) => {
        // console.log(xhr.responseText);
        const fileName = JSON.parse(xhr.responseText)["file"] as string;

        const { data, error } = await create({
          entity: {
            // id:
            // type: "IMAGE",
            type: getMediaType(file),
            fileName,
            dir: "media",
          },
          token,
        });

        if (!error && data) setItems([...items, data]);
      },
    });
  };

  useEffect(() => {
    const run = async () => {
      const { data, error } = await getAll({});

      if (!error && data) {
        setItems(data);
      }
    };

    run();
  }, []);

  return (
    <MediaFinder
      items={items}
      getImageUrl={getImageUrl}
      onClose={onClose}
      onSelect={(selectedIds) => {
        onSelect(
          items
            .filter((item) => selectedIds.includes(item._id))
            .map((item) => ({ ...item, imageUrl: getImageUrl(item.fileName) }))
        );
      }}
      onUpload={handleMediaUpload}
      onDelete={(ids) => {
        ids.forEach((id) => {
          del({ token: "", id: `${id}` });
        });
        setItems(items.filter(({ _id }) => !ids.includes(_id)));
      }}
    />
  );
};

export default MediaFinderContainer;
