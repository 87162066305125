import Container from "components/MainDetails/Container";
import List from "./List";
import Details from "./Details";

const Company = () => {
  return (
    <Container>
      <List stateName="company" />
      <Details />
    </Container>
  );
};

export default Company;
