import { createStateHooks } from "lib/createStateHooks";
import { useToken } from "states/auth/hooks";
import { getAll } from "api/article";
import { listState } from ".";

export const { useLoadItems } = createStateHooks({
  listState,
  api: { getAll },
  useToken,
});
