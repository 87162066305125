/** S = 成功时返回类型 */
export type Result<S> = { error?: string; data?: S };

export const getErrorMessage = (err: any): string => {
  return err instanceof Error
    ? err.message
    : typeof err === "string"
    ? err
    : typeof err === "object"
    ? err.error || err.err || JSON.stringify(err)
    : "";
};

/** 处理使用fetch返回的结果 */
export const handleFetch = async <E>(
  fetcher: () => Promise<Response>
): Promise<Result<E>> => {
  try {
    const res = await fetcher();

    if (res.ok) {
      const resJson: E = await res.json();

      return {
        error: "",
        data: resJson,
      };
    }

    const err = await res.json();
    throw err;
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

/** 处理使用微信request返回的结果 */
export const handleRequest = async <R>(
  requester: () => Promise<{ data: R }>
): Promise<Result<R>> => {
  try {
    const { data } = await requester();

    return {
      error: "",
      data,
    };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
