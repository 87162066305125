import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getAll } from "api/enrollment";
import { useToken } from "states/auth/hooks";
import { currentArticleIdState } from "states/enrollment";
import styles from "./index.module.scss";

const Details = () => {
  const currentArticleId = useRecoilValue(currentArticleIdState);
  const [list, setList] = useState<Array<Enrollment>>([]);

  const token = useToken();

  useEffect(() => {
    const run = async () => {
      if (!currentArticleId) {
        setList([]);
        return;
      }

      const { data, error } = await getAll({
        token,
        query: `article=${currentArticleId}`,
      });

      if (!error && data) setList(data);
    };

    run();
  }, [currentArticleId, token]);

  return (
    <div className={styles.details}>
      <div className={styles.title}>报名统计</div>
      <table>
        <thead>
          <tr>
            <td>姓名</td>
            <td>公司</td>
            <td>电话</td>
            <td>电子邮箱</td>
          </tr>
        </thead>
        <tbody>
          {list.map((enrollment) => (
            <tr key={enrollment._id}>
              <td>{enrollment.name}</td>
              <td>{enrollment.company}</td>
              <td>{enrollment.mobile}</td>
              <td>{enrollment.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Details;
