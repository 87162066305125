import { apiUrl } from "config";
import * as createApi from "lib/createRestApiClient";

const name = "api/admin/jobs";

export const getAll = createApi.getAll<JobView>(apiUrl, name);
export const getOne = createApi.getOne<JobView>(apiUrl, name);
export const create = createApi.create<Job>(apiUrl, name);
export const update = createApi.update<Job>(apiUrl, name);
export const del = createApi.del<Job>(apiUrl, name);
