import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useToken } from "states/auth/hooks";
import * as companyhooks from "states/company/hooks";
import { useLoadItems } from "states/job/hooks";
import { currentIdState, itemState } from "states/job";
import { listState as companyListState } from "states/company";
import { create, update } from "api/job";
import DetailsForm from "./DetailsForm";

const stateName = "job";
const companyStateName = "company";

const Details = () => {
  const [currentId, setCurrentId] = useRecoilState(currentIdState(stateName));
  const currentItem = useRecoilValue(
    itemState({ name: stateName, id: currentId })
  );
  const companyList = useRecoilValue(companyListState(companyStateName));
  const loadItems = useLoadItems(stateName);
  const loadCompanyItems = companyhooks.useLoadItems(companyStateName);
  const token = useToken();

  const save = async (item: Job) => {
    if (currentId) {
      await update({
        id: currentId,
        entity: item,
        token,
      });
    } else {
      const { data } = await create({
        entity: item,
        token,
      });

      if (data) setCurrentId(data._id);
    }

    await loadItems();
  };

  useEffect(() => {
    loadCompanyItems();
  }, [loadCompanyItems]);

  return (
    <DetailsForm
      id={currentId}
      item={currentItem}
      companyList={companyList}
      onSave={save}
    />
  );
};

export default Details;
