import { atomFamily, DefaultValue, selectorFamily } from "recoil";

interface Option<E> {
  name: string;
  getId: (v: E) => string;
}

export const createState = <E>({ name, getId }: Option<E>) => {
  const listState = atomFamily<Array<E>, string>({
    key: `${name}/list`,
    default: (_name: string) => [],
  });

  const currentIdState = atomFamily<string, string>({
    key: `${name}/currentId`,
    default: (_name: string) => "",
  });

  const itemState = selectorFamily<E | undefined, { name: string; id: string }>(
    {
      key: `${name}/item`,
      get:
        ({ name, id }) =>
        ({ get }) => {
          const list = get(listState(name));
          return list.find((item) => getId(item) === id);
        },
      set:
        ({ name, id }) =>
        ({ get, set }, value) => {
          if (value && !(value instanceof DefaultValue)) {
            const list = get(listState(name));
            const index = list.findIndex((item) => getId(item) === id);

            if (index !== -1 && id === getId(list[index]))
              set(listState(name), [
                ...list.slice(0, index),
                value,
                ...list.slice(index),
              ]);
          }
        },
    }
  );

  return { listState, currentIdState, itemState };
};
