import { Form, Field } from "react-final-form";
import { formatTags, parseTags } from "components/common";
import FormRow from "components/MainDetails/FormRow";
import styles from "./index.module.scss";

type FormValues = Omit<Company, "tags"> & { tags?: string };

interface Props {
  id: string;
  item?: Company;
  onSave: (item: Company) => Promise<any>;
}

const DetailsForm = ({ id, item, onSave }: Props) => {
  const submit = async (values: FormValues) => {
    const tags = parseTags(values.tags);

    await onSave({ ...values, tags });
  };

  return (
    <div className={styles.details}>
      <div className={styles.title}>{id ? "编辑" : "创建"}</div>

      <Form<FormValues>
        key={id}
        initialValues={{ ...item, tags: formatTags(item?.tags) }}
        onSubmit={submit}
      >
        {({ handleSubmit, form, submitting }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            {id && <FormRow label="编号" control={id} />}

            {/*  parse={(value) => value} 保证空字符串能出现在FormValues中 */}
            <Field
              name="name"
              parse={(value) => value}
              validate={(v) => (v ? undefined : "必填")}
            >
              {({ input, meta }) => (
                <FormRow
                  label="名称"
                  control={<input {...input} type="text" placeholder="名称" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="tags">
              {({ input, meta }) => (
                <FormRow
                  label="标签（每个一行）"
                  control={
                    <textarea {...input} placeholder="标签（每个一行）" />
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="email" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="邮箱"
                  control={<input {...input} type="text" placeholder="邮箱" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <div className={styles.buttons}>
              <button color="secondary" onClick={() => form.reset()}>
                重置
              </button>
              <button color="primary" type="submit" disabled={submitting}>
                {submitting ? "提交中..." : "提交"}
              </button>
              {/* {hint && (
                    <span style={{ fontSize: "0.85em", color: "red" }}>{hint}</span>
                  )} */}
            </div>
          </form>
        )}
      </Form>
      {/* <pre>{JSON.stringify(currentItem, null, 2)}</pre> */}
    </div>
  );
};

export default DetailsForm;
