import { formData } from "./formData";
import { Result, handleFetch } from "./handleResponse";

/** id 字段类型 */
export type IdType = string | number;

export interface GetOption {
  token?: string;
  query?: string | Record<string, string>;
}
export type GetAll<E> = (option: GetOption) => Promise<Result<Array<E>>>;

export interface GetOneOption<I extends IdType> {
  token: string;
  id: I;
}
export type GetOne<E, I extends IdType> = (
  option: GetOneOption<I>
) => Promise<Result<E>>;

export interface DelOption<I extends IdType> {
  id: I;
  token: string;
}
export type Del<E, I extends IdType> = (
  option: DelOption<I>
) => Promise<Result<E | unknown>>;

/**
 * contentType 一般情况下使用json，如果含文件的上传，使用form
 */
export interface UpdateOption<E, I extends IdType> {
  id: I;
  entity: E;
  token: string;
  contentType?: "form" | "json";
}
export type Update<E, I extends IdType> = (
  option: UpdateOption<E, I>
) => Promise<Result<E>>;

/**
 * contentType 一般情况下使用json，如果含文件的上传，使用form
 */
export interface CreateOption<E, K extends string> {
  entity: Omit<E, K>;
  token: string;
  contentType?: "form" | "json";
}
export type Create<E, K extends string = "_id"> = (
  option: CreateOption<E, K>
) => Promise<Result<E>>;

/**
 * E: entity type
 *
 * I: Id type
 *
 * @param apiUrl url for api server
 * @param name resource name
 */

export const getAll: <E>(apiUrl: string, name: string) => GetAll<E> =
  (apiUrl, name) =>
  async ({ token, query }) => {
    return handleFetch(async () => {
      const headers: Record<string, string> = {};
      if (token) headers["Authorization"] = `Bearer ${token}`;

      const search =
        typeof query === "string"
          ? query
          : typeof query === "object"
          ? new URLSearchParams(query).toString()
          : "";

      return await fetch(
        search ? `${apiUrl}/${name}?${search}` : `${apiUrl}/${name}`,
        {
          method: "GET",
          headers,
          referrerPolicy: "no-referrer",
          mode: "cors",
          cache: "no-cache",
          credentials: "omit",
        }
      );
    });
  };

export const getOne: <E, I extends IdType = string>(
  apiUrl: string,
  name: string
) => GetOne<E, I> =
  (apiUrl, name) =>
  async ({ token, id }) => {
    return handleFetch(async () => {
      const headers: Record<string, string> = {};
      if (token) headers["Authorization"] = `Bearer ${token}`;

      return await fetch(`${apiUrl}/${name}/${id}`, {
        method: "GET",
        headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
      });
    });
  };

export const del: <E, I extends IdType = string>(
  apiUrl: string,
  name: string
) => Del<E, I> =
  (apiUrl, name) =>
  async ({ id, token }) => {
    return handleFetch(async () => {
      const headers: Record<string, string> = {};
      if (token) headers["Authorization"] = `Bearer ${token}`;

      return await fetch(`${apiUrl}/${name}/${id}`, {
        method: "DELETE",
        headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
      });
    });
  };

export const update: <E, I extends IdType = string>(
  apiUrl: string,
  name: string
) => Update<E, I> =
  (apiUrl, name) =>
  async ({ id, entity, token, contentType = "json" }) => {
    return handleFetch(async () => {
      const headers: Record<string, string> = {};
      if (token) headers["Authorization"] = `Bearer ${token}`;

      headers["Content-Type"] =
        contentType === "form" ? "multipart/form-data" : "application/json";

      return await fetch(`${apiUrl}/${name}/${id}`, {
        method: "PUT",
        headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        body:
          contentType === "form" ? formData(entity) : JSON.stringify(entity),
      });
    });
  };

export const create: <E>(apiUrl: string, name: string) => Create<E> =
  (apiUrl, name) =>
  async ({ entity, token, contentType = "json" }) => {
    return handleFetch(async () => {
      const headers: Record<string, string> = {};
      if (token) headers["Authorization"] = `Bearer ${token}`;

      headers["Content-Type"] =
        contentType === "form" ? "multipart/form-data" : "application/json";

      return await fetch(`${apiUrl}/${name}`, {
        method: "POST",
        headers,
        referrerPolicy: "no-referrer",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        body:
          contentType === "form" ? formData(entity) : JSON.stringify(entity),
      });
    });
  };
