import { useCallback } from "react";
import { RecoilState, useSetRecoilState } from "recoil";
import { type GetAll, IdType } from "./createRestApiClient";

interface Options<T, I extends IdType = string> {
  // getId: (item: T) => I;
  // currentIdState: (name: string) => RecoilState<I>;
  listState: (name: string) => RecoilState<Array<T>>;
  //   api: API<T, I>;
  api: {
    getAll: GetAll<T>;
  };
  useToken: () => string;
}

export const createStateHooks = <T, I extends IdType = string>({
  // getId,
  // currentIdState,
  listState,
  api,
  useToken,
}: Options<T, I>) => {
  const useLoadItems = (name: string) => {
    const setList = useSetRecoilState(listState(name));
    const token = useToken();

    return useCallback(async () => {
      const { data } = await api.getAll({ token });
      if (data) setList(data);
    }, [setList, token]);
  };

  return { useLoadItems };
};
