export const formatTags = (value?: string[]) => {
  return value ? value.join("\n") : "";
};

export const parseTags = (text?: string) => {
  if (!text) return [];

  const lines = text
    .trim()
    .split("\n")
    .filter((line) => line?.trim());

  return lines;
};
