import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Main from "components/MainDetails/Main";
import { useToken } from "states/auth/hooks";
import { useLoadItems } from "states/figure/hooks";
import { currentIdState, listState } from "states/figure";
import { del } from "api/figure";

interface Props {
  stateName: string;
}

const List = ({ stateName }: Props) => {
  const [currentId, setCurrentId] = useRecoilState(currentIdState(stateName));
  const list = useRecoilValue(listState(stateName));
  const token = useToken();
  const loadItems = useLoadItems(stateName);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <Main
      title="创业园"
      itemIds={list.map(({ _id }) => _id)}
      renderItem={(id) => (
        <span>{list.find(({ _id }) => id === _id)?.name || "未命名"}</span>
      )}
      onSelect={setCurrentId}
      onCreate={() => setCurrentId("")}
      onRemove={async (id) => {
        if (window.confirm("确认删除？")) {
          await del({ id, token });
          await loadItems();
          if (id === currentId) setCurrentId("");
        }
      }}
      currentId={currentId}
    />
  );
};

export default List;
